import React, { useState, useEffect, useRef } from 'react';
import logo from './logo.svg';
import './App.css';
/** Third Party */
import axios from 'axios';
import Pdf from 'react-to-pdf';
import Swal from 'sweetalert2';



function App() {

  const ref : any = React.createRef();

  const [cardNumber, setCardNumber] = useState<string>('');
  const [data, setData] = useState<{[key: string]: any}>({});

  const getData = async (e: any) : Promise<void> => {
    e.preventDefault();
    try {
      const response = await axios.get(`http://backend.fsolteam.com/api/getData?card_number=${cardNumber}`);
      setData(response.data.result);
    } catch(error : any) {
      const errorResponse = {error};
      const errorMessage = errorResponse.error.response.data.message;
      Swal.fire(errorMessage, '', 'error');
    }
  };

  const renderDate = () : string => {
    const date = new Date();
    const days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
    const month = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
    if(String(date.getDate()).length < 2) {
      return `${days[date.getDay()]}, 0${date.getDate()} ${month[date.getMonth()]} ${date.getFullYear()}`;
    } else {  
      return `${days[date.getDay()]}, ${date.getDate()} ${month[date.getMonth()]} ${date.getFullYear()}`;
    }
  };

  return (
    <div className='container'>
      <form className='form' onSubmit={getData}>
        <div className='input-container'>
          <label className='label'>No.Kartu:</label>
          <input className='input' type="text" value={cardNumber} onChange={(e) => setCardNumber(e.target.value)}/>
        </div>
      </form>
      {
        Object.keys(data).length > 0 && (
          <div className="result">
            <div className='card'>
              <div className='card-content'>
                <h1 className='card-title'>Detail Nasabah</h1>
                <div className="detail-content">
                  <div className="detail-title-container">
                    <p>Nomer CIF:</p>
                    <p>Nama Nasabah:</p>
                    <p>Outstanding Pokok:</p>
                    <p>Outstanding Margin:</p>
                    <p>Outstanding Discount:</p>
                    <p>DPD (Due per dat):</p>
                  </div>
                  <div className="detail-value-container">
                    <p>{data.number_base}</p>
                    <p>{data.name}</p>
                    <p>Rp. {data.outstanding_pokok}</p>
                    <p>{data.outstanding_margin}</p>
                    <p>Rp. {data.program_discount}</p>
                    <p>{data.dpd}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='print-container' ref={ref}>
              <h1 className='print-title'>Surat Permohonan Pelunasan</h1>
              <p className='print-text-perjanjian'>Saya yang bertanda tangan dibawah ini bermaksud untuk mengajukan pelunasan Pembiayaan di Bank Muamalat Indonesia : </p>
              <table>
                <tr>
                  <td>Nama Nasabah</td>
                  <td>{data.name}</td>
                </tr>
                <tr>
                  <td>No Kartu</td>
                  <td>{data.no_card}</td>
                </tr>
                <tr>
                  <td>DPD</td>
                  <td>{data.dpd}</td>
                </tr>
                <tr>
                  <td>Total Kewajiban</td>
                  <td>Rp. {data.program_discount}</td>
                </tr>
              </table>
              <p className='print-text-perjanjian'>Adapun jaminan yang akan diserahkan Bank Muamalat Indonesia atas pelunasan di atas adalah sebagai berikut:</p>
              <table>
                <tr>
                  <th>No</th>
                  <th>Jenis Jaminan</th>
                  <th>Dokumen Jaminan</th>
                  <th>Jenis Pengikatan</th>
                  <th>No & Tanggal Dokumen</th>
                </tr>
                <tr>
                  <td>1</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>2</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>3</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>4</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </table>
              <p className='print-text-perjanjian'>Demikian surat permohonan ini dibuat secara sadar dan bertanggung jawab tanpa ada paksaan atau intimidasi dari pihak manapun</p>
              <div className='approval-container'>
                <div className='approval-container-header'>
                  <p>{renderDate()}</p>
                  <p className='text-known'>Mengetahui</p>
                </div>
                <div className='approval-content'>
                  <p>{data.name}</p>
                  <p className='text-br'>[BR, BCH]</p>
                  <p className='text-bcom'>[BCOM]</p>
                </div>
              </div>
            </div>
            <Pdf targetRef={ref} filename="code-example.pdf">
              {({ toPdf }: any) => <button onClick={toPdf}>Generate Pdf</button>}
            </Pdf>
          </div>
        )
      }
    </div>
  );
}

export default App;
